#root {
    // background-image: url("/assets/imgs/background-green.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.card {
    background: #ffffff 0% 0% no-repeat padding-box !important;
    border-radius: 15px !important;
    opacity: 1 !important;
}

.app,
app-dashboard,
app-root {
    display: flex;
    flex-direction: column;
    margin: 1% 0px;
    min-height: auto !important; 
}

.container-page {
    height: auto !important;
    display: flex !important;
    flex-direction: column !important;

    &.container-payment {
        height: auto !important;
        min-height: 100vh !important;
    }
}

.h-150 {
    height: 150% !important;
}

.app.back-logo {
    & .text-muted {
        color: #303084 !important;
        font-weight: 800;
    }

    & .text-center {
        text-align: center;
    }

    & hr {
        margin: 15px 0px 0px 0px;
        padding: 20px 20px 0px 0px;
        display: flex;
    }

    & button {
        padding: 10px 20px 10px 20px;
        font-size: 18px;
    }

    .inputs-card-data {
        font-size: 16px !important;
        border-top: none;
        border-right: none;
        border-left: none;
        border-radius: 0 !important;
    }

    & .border-top {
        border-top: 4px solid #f9d537 !important;
        border-radius: 0.375em 0.375em 0 0;
    }

    & .container-logo {
        display: grid;
        grid-template-columns: 12em 1fr;
        align-items: center;

        & .justify-content-left {
            grid-row: 1 / span 2;
            display: flex;
            justify-content: flex-end;

            & .logo {
                height: 100px;
            }
        }

        & .title {
            font-size: 1.2em;
            font-weight: 300;
            padding-left: 0.5em;
            padding-bottom: 0;
            margin-bottom: -25px;
        }

        & .sub-title {
            font-size: 1.75em;
            padding-left: 0.3em;
            font-weight: 500;
            margin-top: -20px;
        }
    }

    & .checkout {
        & .card {
            margin: 5px 10px 15px 5px;
            float: left;
            width: 80px;
            height: 50px;
            cursor: pointer;
            box-shadow: 2px 2px #888888;
        }

        & .active {
            border: 1.7px solid darken($color: #303084, $amount: 5);
        }
    }
}

.label-side-info {
    font-size: 22px !important;
    color: #58595b !important;
}

.header-full {
    height: 126px;
    top: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-bottom: 3px solid;

    .row {
        margin: 0;
    }
}

.resume-box {
    background: #e2e2e2 0% 0% no-repeat padding-box !important;

    strong {
        font-size: 23px;
    }

    label,
    span {
        font-size: 17px;
    }
}

.resume-view {
    strong {
        font-size: 23px;
    }

    label,
    span {
        font-size: 17px;
    }
}

label > strong {
    font-size: 23px !important;
}

body {
    font-family: Roboto Regular !important;
}

input,
select,
button {
    border-radius: 20px !important;
    height: 51px !important;
    font-size: 19px !important;
}
